<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Take a look around Amchara Malta</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="container my-5">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550">
            A Beautiful Retreat in the Mediterranean Sunshine
          </h2>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="container my-5">
      <div class="row">
        <div class="gal">
          <img
            v-for="i in maltaImages"
            class="img-responsive"
            :src="'/images/malta-retreat/' + i"
            alt="Amchara Health Retreat, Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img2.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img3.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img4.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <!--<img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img5.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />-->
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img23.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img7.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img8.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            v-for="i in maltaGalleryImages"
            class="img-responsive"
            :src="'/images/malta-retreat/' + i"
            alt="Amchara Health Retreat, Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img9.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img10.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img11.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img12.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img13.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img14.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />

          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img18.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img20.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img21.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img22.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img6.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
          <!--<img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img25-2.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />-->
          <img
            src="https://storage.googleapis.com/amchara-205620.appspot.com/storage/app/public/wp-content/uploads/2017/02/img27-2.png"
            alt="Amchara Health Retreat Gozo, Malta"
          />
        </div>
      </div>
      <router-link
        to="/enquiries"
        class="btn btn-orange-gradient btn-block mt-3"
        >ENQUIRE NOW</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      maltaImages: [],
      maltaGalleryImages: [],
      bgImages: [require("@/assets/images/header/malta_header.jpg")],
    };
  },
  methods: {
    fetchGalleryImages() {
      window.axios.get("/fetch-malta-gallery-images").then(({ data }) => {
        this.maltaImages = data.malta;
        this.maltaGalleryImages = data.malta_gallery;
      });
    },
  },
  mounted() {
    this.fetchGalleryImages();
  },
};
</script>

<style>
</style>